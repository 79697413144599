/* client/src/pages/chat/styles.module.css */
@media only screen and (min-width: 501px) {
    .chatContainer {
    /*   max-width: 1100px; */
      margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(5%, 120px) 75fr 25fr;
/*       gap: 20px; */
    /*   grid-auto-rows: minmax(100px, auto); */
    }
}

@media only screen and (max-width: 500px) {
        .chatContainer {
    /*   max-width: 1100px; */
      margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(5%, 80px) 1fr;
    /*   grid-auto-rows: minmax(100px, auto); */
    }
    
    .ad{
        display: none;
    }
}

/* Room and users component */
.roomAndUsersColumn {
  border-right: 1px solid #dfdfdf;
}
.roomTitle {
/*   margin-bottom: 60px; */
  text-transform: uppercase;
  font-size: 2rem;
  color: #fff;
}
.usersTitle {
  font-size: 1.2rem;
  color: #fff;
}
.usersList {
  list-style-type: none;
  padding-left: 5px;
  text-align: left;
  margin-bottom: 60px;
  color: rgb(153, 217, 234);
}
.usersList li {
  margin-bottom: 12px;
}

/* Messages */
.messagesColumn {
  height: 85vh;
  overflow: auto;
  padding: 10px 10px 10px 5px;
}
.message {
/*   background: rgb(0, 24, 111); */
/*   border-radius: 6px; */
/*   margin-bottom: 24px; */
/*   max-width: 600px; */
/*   padding: 12px; */
}
.msgMeta {
  color: rgb(153, 217, 234);
  font-size: 0.75rem;
}
.msgText {
    text-align: left;
/*   color: #fff; */
}
.messagesUsername{
    font-sizE: 10pt;
    font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif;
    text-decoration: none;
    font-weight: bold;
    color: #003388;
    cursor: pointer;
}

/* Message input and button */
.sendMessageContainer {
  padding: 16px 20px 20px 16px;
}
.messageInput {
  padding: 14px;
  margin-right: 16px;
  width: 92%;
  border-radius: 6px;
  border: 1px solid rgb(153, 217, 234);
  font-size: 0.9rem;
}
